import React, {useContext, useState} from 'react';
import {Context} from '../../AppContext';
import {Button} from '../../Widgets';
import {isVIP, isMonthly} from '../../Utils/UserUtil';
import CartItemTable from '../../Components/CartItemTable';
import PromotionSection from './PromotionSection';
import {message} from 'antd';
const config = require('../../data.json');

export default function Bag(props) {
  const {goToNextStep, goToPrevStep} = props;
  const app = useContext(Context);
  const {profile, cart, specsError} = app.state;

  let creditsNotAllow =
    isVIP(profile) &&
    !isMonthly(profile) &&
    profile.credits < cart.calculations.amount;

  const handleNextStep = () => {
    if (JSON.stringify(specsError) !== '{}') {
      message.error('請先解決規格錯誤，再進行下一步。');
      return;
    }
    goToNextStep();
  };

  return (
    <div>
      <CartItemTable />

      <PromotionSection />

      {goToNextStep && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: '20px 0px',
          }}>
          <Button
            type="default"
            style={{
              color: config.colors.borderSecond,
              borderColor: config.colors.borderSecond,
            }}
            onClick={goToPrevStep}>
            繼續購物
          </Button>
          <div style={{flex: 1}}></div>
          <Button onClick={handleNextStep}>下一步</Button>
        </div>
      )}

      {creditsNotAllow && (
        <div
          style={{
            margin: '20px 0px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
          您的點數餘額不足。
          <a href="/account_manage" style={{color: config.colors.main}}>
            前往儲值
          </a>
        </div>
      )}
    </div>
  );
}
